import '../css/Services.css';
import { useState, useEffect } from 'react';
// import info from '../icons/information.png'
// import engage from '../icons/approval.png'
// import policy from '../icons/scales.png'
// import elections from '../icons/elections.png'
import info from '../icons/information.svg'
import engage from '../icons/approval.svg'
import policy from '../icons/policy.svg'
import elections from '../icons/elections.svg'

import checklist from '../icons/checklist.svg'
import petition from '../icons/petition.svg'
import raise from '../icons/raise.svg'
import promote from '../icons/promote.svg'



const service_title = {
  "EN":
  { "V" :
    { "l1": "Voter", "l2": "resources",
    "l3":"Fight fake news and media echo chambers by getting unbiased and relevant information on candidates running in your elections. Stay engaged by showing support/opposition for candidates and aspirants, engaging in activism and supporting aspirants running for public offices."
      },
  "C" :
    { "l1": "Candidate", "l2": "resources",
    "l3":"Aspiring to run for office to make change but not sure where and how to start? Get help navigating and completing complex and often expensive filing processes to become a candidate by reviewing the requirements, promoting your candidacy to the mass, getting petitions and raising filing fees."
      }
  },

  "ES":
  { "V" :
    { "l1": "Recursos para", "l2": "Votantes",
    "l3":"Luche contra las cámaras de eco de noticias y medios falsos obteniendo información imparcial y relevante sobre los candidatos que se presentan a sus elecciones. Manténgase comprometido mostrando apoyo / oposición a los candidatos y aspirantes, participando en el activismo y apoyando a los aspirantes a cargos públicos."
      },
  "C" :
    { "l1": "Recursos para", "l2": "Aspirantes",
    "l3":"¿Aspira a postularse para un cargo para hacer cambios pero no está seguro de dónde y cómo empezar? Obtenga ayuda para navegar y completar procesos de presentación complejos y, a menudo, costosos para convertirse en candidato, revisando los requisitos, promoviendo su candidatura a la masa, recibiendo peticiones y aumentando las tarifas de presentación."
      }
  }
}



const services_data = {
  "EN": { "V" :
    [{
      "key": 1, "title": "Get Informed!", "icon1":info,
      "description": "Get relevant and unbiased information about candidates running in your non-presidential elections. Fight fake news and Media echo chambers."
    },
    {
      "key": 2, "title": "Get Engaged!", "icon1":engage,
      "description": "Show support or opposition for candidates, aspirants and their policy stances. Keep track your favourite candidates and final choices."
    },
    {
      "key": 3, "title": "Razor Focus on Policy", "icon1":policy,
      "description": "See how your candidates match with you on policy stance. Communicate your stances to your candidates and other everyday citizens who aspire to run for office."
    },
    {
      "key": 4, "title": "Every Election Matters!", "icon1":elections,
      "description": "Elections at all levels, Federal or State, are important and much of impact tend to happen at state and local level. That is why our focus is on non-presidential down-ballot elections."
    }],

   "C" :
    [{
      "key": 1, "title": "Check requirements","icon1":checklist,
      "description": "Get information on eligibility, deadlines and other requirements needed to successfully file for candidacy. The requirements for petitions and/or filing fees can vary based on state, political office and party affiliation. Get help in navigating this process effectively.",
    },
    {
      "key": 2, "title": "Fulfill requirements", "icon1":petition,
      "description": "Get help fulfilling filing requirements to successfully file for candidacy. Signature needed and fees can be high for some states and offices. Use our platform to gather enough petition signatures and/or raise for high filing fees. When ready, file for candidacy.",
    },
    {
      "key": 3, "title": "Fundraise from Grassroots", "icon1":raise,
      "description": "Raise funds through our platform for high filing fees when needed in some states (funds will be released only when filing for candidacy). Once you are an official candidate, fundraise on your site of choice by promoting your platform and campaign to gather grassroots level support.",
    },
    {
      "key": 4, "title": "Mobilize to make impact", "icon1":promote,
      "description": "Communicate directly with voters about your ideas, policy stances and what you will do to advance their agenda. Learn about voter sentiments on your candidacy in the district you wish to run. Generate candid discussion on agreements and disagreements on policy issues and show that you care.",
    }]
  },

  "ES": { "V":
    [{
      "key": 1, "title": "Mantente Informada!", "icon1":info,
      "description": "Obtenga información relevante e imparcial sobre los candidatos que se postulan en sus elecciones no presidenciales. Lucha contra las cámaras de eco de noticias falsas y medios.",

    },
    {
      "key": 2, "title": "Mantente Comprometido!", "icon1":engage,
      "description": "Muestre apoyo u oposición a los candidatos, aspirantes y sus posturas políticas. Mantenga un registro de sus candidatos favoritos y sus elecciones finales.",
    },
    {
      "key": 3, "title": "Enfoque de Razor en la Política", "icon1":policy,
      "description": "Vea cómo sus candidatos coinciden con usted en la postura política. Comunique sus posturas a sus candidatos y otros ciudadanos comunes que aspiran a postularse para un cargo.",
    },
    {
      "key": 4, "title": "Todas las Elecciones son Importantes", "icon1":elections,
      "description": "Las elecciones en todos los niveles, federal o estatal, son importantes y gran parte del impacto tiende a ocurrir a nivel estatal y local. Es por eso que nuestro enfoque está en las elecciones no presidenciales con voto negativo.",
    }],

   "C":
  [{
      "key": 1, "title": "Consultar requisitos", "icon1":checklist,
      "description": "Obtenga información sobre elegibilidad, fechas límite y otros requisitos necesarios para presentar con éxito la candidatura. Los requisitos para las peticiones y / o tarifas de presentación pueden variar según el estado, el cargo político y la afiliación a un partido. Obtenga ayuda para navegar este proceso de manera efectiva.",

    },
    {
      "key": 2, "title": "Cumplir con los requisitos", "icon1":petition,
      "description": "Obtenga ayuda para cumplir con los requisitos de presentación para presentar una candidatura con éxito. Se necesita firma y las tarifas pueden ser altas para algunos estados y oficinas. Utilice nuestra plataforma para reunir suficientes firmas de peticiones y / o aumentar las tarifas de presentación. Cuando esté listo, presente su candidatura.",
    },
    {
      "key": 3, "title": "Recaudación de fondos de base", "icon1":raise,
      "description": "Recaude fondos a través de nuestra plataforma por altas tarifas de presentación cuando sea necesario en algunos estados (los fondos se liberarán solo cuando se presente una candidatura). Una vez que sea un candidato oficial, recaude fondos en el sitio que elija promocionando su plataforma y campaña para obtener apoyo a nivel de base.",
    },
    {
      "key": 4, "title": "Movilízate para generar impacto", "icon1":promote,
      "description": "Comuníquese directamente con los votantes sobre sus ideas, posturas políticas y qué hará para promover su agenda. Conozca los sentimientos de los votantes sobre su candidatura en el distrito que desea postular. Genere una discusión franca sobre acuerdos y desacuerdos sobre cuestiones de política y demuestre que le importa.",
    }],
  }
}


function ServiceItem(props) {


  return(

    <div className="service-item">
      <div className="icon"><img src={props.icon}/></div>
      <h2> {props.title}</h2>
      <p> {props.description}</p>
    </div>

);}


function Services(props) {

  //  useState using props coming from the parent

  const [language, setLang] = useState(props.language);
  const [cust, setCust] = useState("V");

  // useEffect to apply / update this comp when props from parent change

  useEffect (() => {
    setLang(props.language);
  },[props.language])

  const title_filt = service_title[language][cust];
  const services_filt = services_data[language][cust];


  // const home2_data =  {"ES" : {"l1": "À propos", "l2":"Avec 8 ans d’expérience en recherche académique en France et aux Etats-Unis, j’ai développé des compétences en méthodologie et rigueur scientifique, ainsi qu’en analyse critique.  En tant que consultante indépendante, mon objectif est d’apporter mon expertise et de développer des relations de confiance.",
  //   "l3":"Dr. Clotilde Jumelle"} ,
  //    "EN" : {"l1": "About", "l2":"With 8 years of academic research in both France and the United States, I developed skills in scientific methodology, rigor and critical analysis.  As an independent consultant, my goal is to provide my expertise and establish trust-based relationships.",
  //    "l3":"Dr. Clotilde Jumelle"}}
  // const home2_filt = home2_data[language]


  // return the div with new/ updated props data

  const ServiceItems = services_filt.map( item => <ServiceItem title={item.title} key={item.key} description={item.description} icon={item.icon1}/>)


  return (

    <div className="Services container" id="Services-ID">

      <div className="service-filter flex-row">
        <h4 className={(cust==="V") ? "serv-filt-item active" : "serv-filt-item"} onClick={()=>setCust("V")}> Voters </h4>
        <h4 className={(cust==="C") ? "serv-filt-item active" : "serv-filt-item"} onClick={()=>setCust("C")}> Candidates </h4>
      </div>

      <div className="service-top">
        <h1 className="section-title">{title_filt.l1} <span>{title_filt.l2}</span></h1>
        <p>{title_filt.l3}</p>
      </div>
      <div className="service-bottom">
         {ServiceItems}
      </div>

      <div className='dummy-div' id="Contact"> </div>

    </div>

  );
}

export default Services;
