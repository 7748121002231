import { useState, useEffect } from 'react';
import pic from '../img/Logo.png';

const footer_data =  {"EN" : {"l1": "Stay Informed", "l2":"Stay Engaged", "l3":"Elect Right Candidates", "bttn1":"See Preview"} ,
   "ES" : {"l1": "Mantente Informada", "l2":"Mantente Comprometido", "l3":"Elegir Candidatos Correctos", "bttn1":"Ver Vista Previa"}}


function Footer(props) {

  //  useState using props coming from the parent

  const [language, setLang] = useState(props.language)

  // useEffect to apply / update this comp when props from parent change

  useEffect (() => {
    setLang(props.language);
  },[props.language])

  const footer_filt = footer_data[language];



  // return the div with new/ updated props data


  return (

    <div className="Footer"  id='Footer'>

      <div className="brand">
        <a href="#">
          <img src={pic} alt="logo-electsmart-US"/>
        </a>
      </div>
      <h2 >Stay Infomed, Stay Engaged, Elect Right People</h2>


      <div className='thanks'>
        <p className='copy'>Copyright © 2020 electsmartUS. All rights reserved. Designed and Developed by Milind Patel <a className="pretty-link" href="https://milind-dev.netlify.app" target="_blank" rel="noopener noreferrer" > (Visit Milind's Site)</a> </p>
      </div>
    </div>

  );
}

export default Footer;
