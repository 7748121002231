import '../css/Home.css';
import { useState, useEffect } from 'react';
import pic from '../img/home.jpg'; // gives image path
import external from '../icons/external.svg';

const home_data =  {"EN" : {"l1": "Stay Informed", "l2":"Stay Engaged", "l3":"Elect Right Candidates", "bttn1":"See Preview (2020 Archived)"} ,
   "ES" : {"l1": "Mantente Informada", "l2":"Mantente Comprometido", "l3":"Elegir Candidatos Correctos", "bttn1":"Ver Vista Previa (2020 Archivado)"}}

const home_data2 =  {"EN" : {"l1": "Stay up to date and engaged with upcoming federal, state and local elections.", "l2":"Your Email", "l3":"Elect Right Candidates", "bttn1":"Subscribe", "bttn2":"Join Beta List"} ,
      "ES" : {"l1": "Manténgase actualizado y comprometido con las próximas elecciones federales, estatales y locales.", "l2":"Tu Correo Electrónico", "l3":"Elegir Candidatos Correctos", "bttn1":"Suscribir", "bttn2":"Únete a la Lista Beta"}}

function Home(props) {

  //  useState using props coming from the parent

  const [language, setLang] = useState(props.language)

  // useEffect to apply / update this comp when props from parent change

  useEffect (() => {
    setLang(props.language);
  },[props.language])


  const home_filt = home_data[language];

  const home_filt2 = home_data2[language];



  //
  // const home2_data =  {"ES" : {"l1": "À propos", "l2":"Avec 8 ans d’expérience en recherche académique en France et aux Etats-Unis, j’ai développé des compétences en méthodologie et rigueur scientifique, ainsi qu’en analyse critique.  En tant que consultante indépendante, mon objectif est d’apporter mon expertise et de développer des relations de confiance.",
  //   "l3":"Dr. Clotilde Jumelle"} ,
  //    "EN" : {"l1": "About", "l2":"With 8 years of academic research in both France and the United States, I developed skills in scientific methodology, rigor and critical analysis.  As an independent consultant, my goal is to provide my expertise and establish trust-based relationships.",
  //    "l3":"Dr. Clotilde Jumelle"}}
  // const home2_filt = home2_data[language]


  // return the div with new/ updated props data


  return (

    <div className="Home container"  id='Home'>

      <div className="inner-home flex-row">
        <div className="intro flex-col">
          <h1>{home_filt.l1}<span></span></h1>
          <h1>{home_filt.l2}<span></span></h1>
          <h1>{home_filt.l3}<span></span></h1>
          <div className="flex-row home-bttns">
            <a href='https://electsmart-mvp.netlify.app' type="button" className="cta external" target="_blank" rel="noopener noreferrer">{home_filt.bttn1} <img src={external} style={{width:'20px', height:"20px"}}/></a>
          </div>
        </div>

        <div className="subscribe flex-col">
          <h3>{home_filt2.l1}</h3>
          <form className="subscribe-form" name="subscribe" method="POST">
              <input type="hidden" name="form-name" value="subscribe" />
              <p> <input placeholder={home_filt2.l2} type="email" name="email" /></p>
              <p><button className="cta" type="submit">{home_filt2.bttn1}</button></p>
          </form>
          <div className="home-bttns2">
            <a href='#Services' type="button" className="cta">{home_filt2.bttn1}</a>
            <a href='#Services' type="button" className="cta">{home_filt2.bttn2}</a>
          </div>
        </div>
      </div>

      <div className='dummy-div' id="About"> </div>
    </div>

  );
}

export default Home;
