import '../css/About.css';
import { useState, useEffect } from 'react';
// import pic from '../img/about-cropped.png';
import pic from '../img/about.svg';
// import pic from '../img/about-img.jpg'; // gives image path

const about_data =  {"EN" : {"l1": "About", "l2":"Us", "l3":"", "l4":"We are an early stage startup wanting to grow to be a nonprofit. We want to empower citizens with tools and resources to participate in the democratic process effectively whether as a voter or as an aspiring candidate. This is our beta with limited features. We are fundraising to improve and add more features. We decided we want to be non-profit so we can be 100% committed to citizens and not the other interests. Please consider supporting our crowdfunding and spreading word about us.", "bttn":"Donate"} ,
   "ES" : {"l1": "Sobre", "l2":"Nosotros", "l3":"", "l4": "Somos una startup en etapa inicial que desea convertirse en una organización sin fines de lucro. Queremos empoderar a los ciudadanos con herramientas y recursos para participar en el proceso democrático de manera efectiva, ya sea como votantes o como aspirantes a candidatos. Esta es nuestra versión beta con funciones limitadas. Estamos recaudando fondos para mejorar y agregar más funciones. Decidimos que queremos ser una organización sin fines de lucro para poder estar 100% comprometidos con los ciudadanos y no con los demás intereses. Considere apoyar nuestro crowdfunding y difundir información sobre nosotros.", "bttn":"Donar"}}


function About(props) {

  //  useState using props coming from the parent

  const [language, setLang] = useState(props.language)

  // useEffect to apply / update this comp when props from parent change

  useEffect (() => {
    setLang(props.language);
  },[props.language])


  const about_filt = about_data[language];
  console.log(about_filt);

  // const home2_data =  {"ES" : {"l1": "À propos", "l2":"Avec 8 ans d’expérience en recherche académique en France et aux Etats-Unis, j’ai développé des compétences en méthodologie et rigueur scientifique, ainsi qu’en analyse critique.  En tant que consultante indépendante, mon objectif est d’apporter mon expertise et de développer des relations de confiance.",
  //   "l3":"Dr. Clotilde Jumelle"} ,
  //    "EN" : {"l1": "About", "l2":"With 8 years of academic research in both France and the United States, I developed skills in scientific methodology, rigor and critical analysis.  As an independent consultant, my goal is to provide my expertise and establish trust-based relationships.",
  //    "l3":"Dr. Clotilde Jumelle"}}
  // const home2_filt = home2_data[language]


  // return the div with new/ updated props data


  return (

    <div className="About container"  id='About-ID'>
      <h1 className="section-title">{about_filt.l1} <span>{about_filt.l2}</span></h1>
      <div className = 'about-inner flex-col'>
        <div className="col-left">
          <div className="about-img">
            <img src={pic} alt="img"/>
          </div>
        </div>
        <div className="col-right">
          <p>{about_filt.l3}</p>
          <p>{about_filt.l4}</p>
        </div>

      </div>

      <a href='https://www.gofundme.com/f/electsmartUS' target="_blank" rel="noopener noreferrer" type="button" className="cta">{about_filt.bttn}</a>

      <div className='dummy-div' id="Services"> </div>

    </div>

  );
}

export default About;
